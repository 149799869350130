import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Page Not Found</h1>
          <p>This page does not exist. Feel free to contact us if this is preventing you from using the site properly.</p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
